import React from "react";
import TemplateWrapper from "../../layouts";
import { MarginExcluder } from "../../components/styled-components/structures";
import {
  SuperHeading,
  Subheading,
  Opening,
  TextList,
  TextListItem,
  Strong,
  Regular,
  Paragraph,
} from "../../components/styled-components/elements";

const Choirs = () => (
  <TemplateWrapper>
    <MarginExcluder>
      <SuperHeading>Meeting Times</SuperHeading>
      <Subheading>Where and when are the choirs held?</Subheading>
      <Opening>
        The choirs are held over 3 twelve-week terms each year. All members can
        attend as many choirs each week as they like.
      </Opening>
      <TextList>
        <TextListItem>
          <Strong>Heald Green Village Community Choir</Strong>
          <Regular>
            {" "}
            meets at St Catherine’s Church Hall, Outwood Road, Heald Green, SK8
            3JQ on Tuesday mornings at 10.00am-11.30am.
          </Regular>
        </TextListItem>
        <TextListItem>
          <Strong>Alderley Edge Village Community Choir</Strong>
          <Regular>
            {" "}
            meets at Alderley Edge Methodist Church, Chapel Road, Alderley Edge,
            SK9 7DU on Tuesdays 2.00-3.30pm.
          </Regular>
        </TextListItem>
        <TextListItem>
          <Strong>Bramhall Village Community Choir</Strong>
          <Regular>
            {" "}
            takes place at Centrepoint (behind the Thrift Shop), next to the
            Methodist Church, Bramhall Lane South, Bramhall on Fridays
            2.00pm-3.30pm.
          </Regular>
        </TextListItem>
      </TextList>
      <Paragraph>
        We also hold a Choir+ for members who would like a challenge! This takes
        place in Bramhall on Thursdays 11am-12pm during term time. There is an
        additional weekly £7 charge for this choir - teas and coffees are
        provided.
      </Paragraph>
    </MarginExcluder>
  </TemplateWrapper>
);

export default Choirs;
